<script lang="ts">
export default {
  name: 'LinkedAccountsSwitcherModal',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { computed, defineProps, ref } from 'vue';
import BaseModal from '@/components/atoms/BaseModal/Index.vue';
import BaseButton from '@/components/atoms/BaseButton/Index.vue';
import { useI18n } from '@/composables/useI18n';
import { useApiClient } from '@/composables/useApiClient';
import { useTestHelpers } from '@/composables/useTestHelpers';
import { ILink, ICurrentUser } from '@/components/atoms/LinkedAccountsSwitcher/Index.vue';

interface Props {
  links: ILink[];
  switchPath: string;
  unlinkPath: string;
  currentUser?: ICurrentUser;
  signOutPath: string;
  connectNewPath: string;
}
const props = defineProps<Props>();
const { t } = useI18n();
const { apiClient } = useApiClient();
const { generateTestId } = useTestHelpers();

const modalOpen = ref(false);
const signOutText = computed(() => {
  return props.links.length === 0
    ? t('vue_templates.linked_accounts_switcher.log_out')
    : t('vue_templates.linked_accounts_switcher.logout_all');
});
const switchAccount = async (link: ILink) => {
  try {
    const response = await apiClient.post(props.switchPath, { user_id: link.user_id });
    window.location.href = response.data.redirect_url;
  } catch (error) {
    console.error(error);
  }
};
const unlinkAccount = async (link: ILink) => {
  try {
    await apiClient.delete(`${props.unlinkPath}?link_id=${link.id}`);
  } catch (error) {
    console.error(error);
  }
};
const signOut = async () => {
  try {
    await apiClient.delete(props.signOutPath);
    window.location.href = '/';
  } catch (error) {
    console.error(error);
  }
};
</script>

<template>
  <BaseModal
    v-model:model-value="modalOpen"
    :title="t('vue_templates.linked_accounts_switcher.title')"
    :padded-body="false"
    class="linked-accounts-switcher-modal"
  >
    <div
      v-if="currentUser"
      class="linked-accounts-switcher-modal__account-row"
      :data-test-id="generateTestId('current account', currentUser.company_name)"
    >
      <div class="linked-accounts-switcher-modal__account-logo">
        <img :src="currentUser.company_logo" width="60" height="60" alt="" />
      </div>
      <div class="linked-accounts-switcher-modal__account-details">
        <h4>{{ currentUser.company_name }}</h4>
        <div class="text-muted">{{ currentUser.domain }}</div>
        <div class="text-muted">{{ currentUser.full_name }}</div>
      </div>

      <div class="linked-accounts-switcher-modal__account-actions">
        <div>{{ t('vue_templates.linked_accounts_switcher.logged_in') }}</div>
        <br />
        <strong v-if="links.length > 0">
          <a
            class="text-danger"
            :href="unlinkPath"
            data-method="delete"
            :data-test-id="generateTestId('unlink current account')"
          >
            {{ t('vue_templates.linked_accounts_switcher.unlink_and_logout') }}
          </a>
        </strong>
      </div>
    </div>

    <div
      v-for="link in links"
      :key="link.user_id"
      class="linked-accounts-switcher-modal__account-row"
      :data-test-id="generateTestId('linked account company row', link.company_name)"
    >
      <div class="linked-accounts-switcher-modal__account-logo">
        <img :src="link.logo" width="60" height="60" alt="" />
      </div>
      <div class="linked-accounts-switcher-modal__account-details">
        <h4>{{ link.company_name }}</h4>
        <div class="text-muted">
          {{ link.domain }}
        </div>
        <div class="text-muted">{{ link.user_full_name }}</div>
      </div>

      <div class="linked-accounts-switcher-modal__account-actions">
        <div>
          <a
            role="button"
            tabindex="0"
            :data-test-id="
              generateTestId('linked account switcher modal switch to', link.company_name)
            "
            @click.prevent="switchAccount(link)"
            @keyup.prevent="switchAccount(link)"
          >
            {{ t('vue_templates.linked_accounts_switcher.switch') }}
          </a>
        </div>
        <br />
        <strong>
          <a
            class="text-danger"
            role="button"
            tabindex="0"
            :data-test-id="
              generateTestId('linked account switcher modal unlink', link.company_name)
            "
            @click.prevent="unlinkAccount(link)"
            @keyup.prevent="unlinkAccount(link)"
          >
            {{ t('vue_templates.linked_accounts_switcher.unlink') }}
          </a>
        </strong>
      </div>
    </div>

    <div v-if="links.length < 4" class="modal-body">
      <BaseButton
        :text="t('vue_templates.linked_accounts_switcher.add_another')"
        fa-prepend-icon="fa-circle-plus fa-regular"
        fa-icon-size="xl"
        theme="brand"
        :data-test-id="generateTestId('linked account switcher modal add new button')"
        :link="{ href: props.connectNewPath, 'data-method': 'put' }"
      >
        <span>{{ t('vue_templates.linked_accounts_switcher.add_another') }}</span>
      </BaseButton>
      <p class="text-muted m-t-2">
        {{ t('vue_templates.linked_accounts_switcher.note') }}
      </p>
    </div>
    <template #footer>
      <div class="linked-account-switcher-modal__footer text-center">
        <BaseButton
          theme="learnamp"
          variant="text"
          class="text-center m-b-3"
          :data-test-id="generateTestId('linked account switcher modal sign out button')"
          @click.prevent="signOut"
          @keyup.enter="signOut"
        >
          <span class="text-danger">
            <strong>{{ signOutText }}</strong>
          </span>
        </BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<style lang="scss" scoped>
.linked-accounts-switcher-modal {
  color: inherit;

  .linked-accounts-switcher-modal__account-row {
    display: flex;
    gap: 3rem;
    align-items: center;
    padding: 2rem 3rem;
    flex-direction: row;
    border-bottom: 1px solid $platinum;

    .linked-accounts-switcher-modal__account-logo {
      img {
        max-width: 60px;
        max-height: 60px;
        border-radius: 50%;
        box-shadow: 0 0 8px $platinum;
      }
    }

    .linked-accounts-switcher-modal__account-details {
      flex-grow: 1;
    }
  }
}
</style>
